import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useContext,
  useReducer,
  useRef,
} from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import FileCopyIcon from "@mui/icons-material/ContentCopy";
import ArrowRightIcon from "@mui/icons-material/ArrowForwardIos";
import RestoreIcon from "@mui/icons-material/Restore";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { FILTER_TYPES } from "../dao/types";

import {
  DataGridPro as DataGrid,
  GridActionsCellItem,
  useGridApiRef,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridRowModes,
} from "@mui/x-data-grid-pro";

import { useHistory, Prompt } from "react-router-dom";

import { Box } from "@mui/system";
import { Stack, Typography } from "@mui/material";

import compose from "compose-function";

import { CopyDialog } from "../features/Copy";
import { MassEditDialog } from "../features/MassEdit";
import { AlertDialog } from "./Dialogs/Alert";

import { ActionBar } from "./ActionBar";

import {
  filterReducer,
  addCustomOperator,
  returnVisibleColumns,
  saveDataToLocalStorage,
  readDataFromLocalStorage,
  addEditableOption,
  updateRowModesModel,
  updateClientField,
  handleColumnWidthChange,
  generateEntityKeyForLocalStorage,
  handleRowHighlight,
  getColumnOrderAfterPin,
  handleColumnHeaderClick,
  setField,
} from "../utils/table";

import {
  getEntityReference,
  noop,
  stopPropagation,
  getType,
  processHasPatternInputValue,
  snakeCaseToNormalCase,
  isDate,
  dateToString,
} from "../utils/utils";

import {
  LOADING_STATE_FULFILLED,
  LOADING_STATE_PENDING,
  RESULTS_TO_FETCH,
} from "../config";
import { CellHeader } from "../features/DataTable/CellHeader";
import useBeforeUnload from "../hooks/useBeforeUnload";
import { getFriendlyMessage } from "../utils/form";
import {
  generateControlsArray,
  isInlineEditIconsDisabled,
} from "../features/DataTable/utils";

import { FIELD_DEFAULT_VALUE } from "../features/MassEdit/constants";
import {
  mergeArraysById,
  sortArraysOfObjectsByCommonProperty,
} from "../utils/array";
import UserPermissionContext from "../userPermissionContext";
import UserRights from "../enums/UserRights";
import { DeleteDialog } from "./Dialogs/Delete";
import {
  customNumberOperators,
  customStringOperators,
  customDateOperators,
  customBooleanOperators,
} from "./filters/customFilters";
import {
  DATA_GRID_CONTAINER_STYLES,
  DATA_GRID_FILTER_PANEL_STYLES,
} from "./TableComponent.style";
import InputFieldType from "../enums/InputFieldType";
import { EXPORT_VISIBLE_COLUMNS } from "./ExportDropdown";
import { theme } from "../setting";
import { batchSave } from "../dao/inline_edit";
import { handleUnsuccessfulRecords } from "../features/MassEdit/utils";
import FeedbackContext from "../feedbackContext";
import { SEVERITY } from "./FeedbackComponent";

const DEFAULT_OPTIONS = {
  filter: true,
  hasCopy: false,
  hasDelete: false,
  hasMassEdit: false,
  rowHeight: 30,
  minGridHeight: 600,
  mode: "server",
};

const ROWS_PER_PAGE = RESULTS_TO_FETCH;

const DEFAULT_PINNED_COLUMNS = {
  left: [GRID_CHECKBOX_SELECTION_COL_DEF.field],
  right: ["actions"],
};

const DELETE_ACTIONS = {
  DELETE: "delete",
  RESTORE: "restore",
};

export const DIALOG_EDIT_TOGGLE = "DIALOG_EDIT_TOGGLE";
export const DIALOG_COPY_HIDE = "DIALOG_COPY_HIDE";
export const DIALOG_COPY_SHOW = "DIALOG_COPY_SHOW";
export const DIALOG_INFO_TOGGLE = "DIALOG_INFO_TOGGLE";
export const DIALOG_DELETE_TOGGLE = "DIALOG_DELETE_TOGGLE";
export const CONTROLS_ENABLE = "CONTROLS_ENABLE";
export const CONTROLS_DISABLE = "CONTROLS_DISABLE";
export const SELECT_ROW = "SELECT_ROW";
export const COPY_ENTRY = "COPY_ENTRY";
export const SET_VISIBLE_COLUMNS = "SET_VISIBLE_COLUMNS";
export const REFRESH_SELECTED_ROW_STATE = "REFRESH_SELECTED_ROW_STATE";
export const INLINE_EDIT_BATCH_ACTIONS_TOGGLE =
  "INLINE_EDIT_BATCH_ACTIONS_TOGGLE";
export const SET_BATCH_ROW_CHANGES = "SET_BATCH_ROW_CHANGES";
export const SET_INLINE_EDIT_SAVE_PROGRESS = "SET_INLINE_EDIT_SAVE_PROGRESS";

export function initialUIState() {
  return {
    copy: {
      visible: false,
      rows: [],
    },
    edit: {
      visible: false,
    },
    info: {
      visible: false,
      content: "",
    },
    delete: {
      visible: false,
      entity: {},
    },
    inlineEdit: {
      batchRowChanges: {
        cancel: [],
        save: [],
      },
      batchActionButtonsDisabled: true,
      saveInProgress: false,
    },
    controlsDisabled: true,
    selectedRows: [],
    visibleColumns: {},
  };
}

export const uiReducer = (state, action) => {
  switch (action.type) {
    case DIALOG_EDIT_TOGGLE:
      return { ...state, edit: { visible: !state.edit.visible } };
    case DIALOG_COPY_HIDE:
      return { ...state, copy: { visible: false, rows: [] } };
    case DIALOG_COPY_SHOW:
      return { ...state, copy: { visible: true, rows: action.payload } };
    case DIALOG_INFO_TOGGLE:
      return {
        ...state,
        info: { content: action.payload, visible: !state.info.visible },
      };
    case DIALOG_DELETE_TOGGLE:
      return {
        ...state,
        delete: { visible: !state.delete.visible, entity: action.payload },
      };
    case CONTROLS_ENABLE:
      return { ...state, controlsDisabled: false };
    case CONTROLS_DISABLE:
      return { ...state, controlsDisabled: true };
    case INLINE_EDIT_BATCH_ACTIONS_TOGGLE:
      return {
        ...state,
        inlineEdit: {
          ...state.inlineEdit,
          batchActionButtonsDisabled: action.payload,
        },
      };
    case SELECT_ROW:
      return { ...state, selectedRows: action.payload };
    case SET_VISIBLE_COLUMNS:
      return { ...state, visibleColumns: { ...action.payload } };
    case REFRESH_SELECTED_ROW_STATE:
      return {
        ...state,
        selectedRows: state.selectedRows.map((row) => ({
          ...row,
          [action.payload.editedProperty]:
            action.payload.newValue ?? FIELD_DEFAULT_VALUE,
        })),
      };
    case SET_BATCH_ROW_CHANGES:
      return {
        ...state,
        inlineEdit: {
          ...state.inlineEdit,
          batchRowChanges: {
            ...state.inlineEdit.batchRowChanges,
            [action.payload.operation]: action.payload.value,
          },
        },
      };
    case SET_INLINE_EDIT_SAVE_PROGRESS:
      return {
        ...state,
        inlineEdit: {
          ...state.inlineEdit,
          saveInProgress: action.payload,
        },
      };
    default:
      return state;
  }
};

export default function TableComponent({
  columns = [],
  loading,
  rows = [],
  refreshData,
  renderCreateEntityButton = () => null,
  tableOptions = {},
  editRecords = noop.reject,
  exportRecords = noop.reject,
  copyEntry = noop.reject,
  softDeleteEntry = noop.reject,
  hardDeleteEntry = noop.reject,
  restoreEntry = noop.reject,
  setFilters = noop.fn,
  setOffset = noop.fn,
  offset,
  setLimit = noop.fn,
  numberOfRecords,
  isAllowedToExport,
  entity,
  initialFilterModel,
  nestedEntity = false,
  sortModel,
  setSortModel,
  assignModal = false,
  transformColumns = noop.fn,
}) {
  // =========================
  // Context
  // =========================

  const { setFeedback } = useContext(FeedbackContext);
  const { isAllowedTo } = useContext(UserPermissionContext);
  const isAllowedToHardDelete = isAllowedTo(UserRights.HARD_DELETE, entity);
  // =========================
  // State
  // =========================
  const [filterButtonEl, setFilterButtonEl] = useState(null);
  const [filteredRows, setFilteredRows] = useState(rows);
  const [uiState, dispatchUIState] = useReducer(
    uiReducer,
    null,
    initialUIState
  );

  const [isExportingVisibleColumns, setIsExportingVisibleColumns] =
    useState(false);
  const [isExportingAllColumns, setIsExportingAllColumns] = useState(false);
  const [filterString, setFilterString] = useState("");
  const [gridColumns, setGridColumns] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(
    readDataFromLocalStorage(
      generateEntityKeyForLocalStorage(entity, "rowsPerPage", {
        nestedEntity,
        assignModal,
      })
    ) || ROWS_PER_PAGE
  );
  const [currentPage, setCurrentPage] = useState(
    readDataFromLocalStorage(
      generateEntityKeyForLocalStorage(entity, "page", {
        nestedEntity,
        assignModal,
      })
    ) || 0
  );

  // Refs to track copy operation scroll position
  const copyScrollPositionRef = useRef(null);
  const copiedFromIdRef = useRef(null);

  // =========================
  // Event Handlers
  // =========================

  const handlePageChange = (pageIndex) => {
    setCurrentPage(pageIndex);
    // Prevent saving the pageIndex for nested entities (e.g. cable sub overview of cableBundle)
    if (nestedEntity && entity !== FILTER_TYPES.cable) return;
    saveDataToLocalStorage(
      generateEntityKeyForLocalStorage(entity, "page", {
        nestedEntity,
        assignModal,
      }),
      pageIndex
    );
  };

  const handleRowsPerPageChange = (rowsPerPage) => {
    setRowsPerPage(rowsPerPage);
    saveDataToLocalStorage(
      generateEntityKeyForLocalStorage(entity, "rowsPerPage", {
        nestedEntity,
        assignModal,
      }),
      rowsPerPage
    );
  };

  const handleInfoClick = (description) => (e) => {
    stopPropagation(e);
    dispatchUIState({ type: DIALOG_INFO_TOGGLE, payload: description });
  };

  const handleCopyClick = ({ row: entry }) => {
    dispatchUIState({ type: DIALOG_COPY_SHOW, payload: [entry] });
  };

  const apiRef = useGridApiRef();

  const [pinnedColumns, setPinnedColumns] = useState(
    readDataFromLocalStorage(
      generateEntityKeyForLocalStorage(entity, "pinnedColumns", {
        nestedEntity,
        assignModal,
      })
    ) ?? DEFAULT_PINNED_COLUMNS
  );

  const [filterModel, setFilterModel] = useState(initialFilterModel);

  const history = useHistory();

  // Defaults

  const options = {
    ...DEFAULT_OPTIONS,
    ...tableOptions,
  };

  const addRenderHeader = useCallback((item) => {
    return {
      ...item,
      renderHeader: (params) => (
        <CellHeader
          unit={params.colDef.unit}
          name={params.colDef.headerName}
          description={params.colDef.description}
          handleClick={handleInfoClick}
          inlineEditable={item.inline_editable && isInlineEditingEnabled}
        />
      ),
    };
  }, []);

  const modifyColumns = useCallback(
    (rawColumns) => {
      return rawColumns.reduce((acc, item) => {
        return [
          ...acc,
          compose(
            addRenderHeader,
            setField({ minWidth: 50 }),
            setField({ align: "left" }),
            setField({ headerAlign: "left" }),
            addEditableOption,
            addCustomOperator(InputFieldType.NUMBER)(customNumberOperators),
            addCustomOperator(InputFieldType.STRING)(customStringOperators),
            addCustomOperator(InputFieldType.BOOLEAN)(customBooleanOperators),
            addCustomOperator(InputFieldType.DATE)(customDateOperators),
            addCustomOperator(InputFieldType.CABLE_BUNDLES_TYPES)(
              customNumberOperators
            )
          )(item),
        ];
      }, []);
    },
    [addRenderHeader]
  );

  async function copyHandler(newRecordNumber, nTimes) {
    const [entry] = uiState.copy.rows;
    const { number } = entry;

    // Remember current scroll position before refresh, so user stays in the same view area
    copyScrollPositionRef.current = apiRef.current?.getScrollPosition();
    // Remember which row was copied to highlight it after refresh
    copiedFromIdRef.current = entry.id;

    setFeedback({
      isOpen: true,
      messages: [`Copying record #${number}, ${nTimes} times`],
      severity: SEVERITY.INFO,
    });

    try {
      await copyEntry(entry, {
        type: entity,
        startNumber: newRecordNumber,
        nTimes,
      });
      setFeedback({
        isOpen: true,
        messages: [`Copied record #${number}, ${nTimes} times`],
        severity: SEVERITY.SUCCESS,
      });
      refreshData(filterString);
    } catch (error) {
      const message = error.response?.body?.status?.message || "";
      setFeedback({
        isOpen: true,
        messages: [message],
        severity: SEVERITY.ERROR,
      });
      // Clear refs on error
      copyScrollPositionRef.current = null;
      copiedFromIdRef.current = null;
    }
  }

  async function editRecordsHandler(payload) {
    setFeedback({
      isOpen: true,
      messages: [`Updating ${payload.ids.length} records`],
      severity: SEVERITY.INFO,
    });

    try {
      const response = await editRecords(payload);

      if (response.status === 206) {
        handleUnsuccessfulRecords(response);
      }

      Object.entries(payload.fields).forEach(([key, value]) => {
        dispatchUIState({
          type: REFRESH_SELECTED_ROW_STATE,
          payload: { editedProperty: key, newValue: value },
        });
      });

      setFeedback({
        isOpen: true,
        messages: [`Updated ${payload.ids.length} records`],
        severity: SEVERITY.SUCCESS,
      });

      refreshData(filterString);
    } catch (error) {
      setFeedback({
        isOpen: true,
        messages: [error.message],
        severity: SEVERITY.ERROR,
      });
    }
  }

  const handleSortModelChange = (newSortModel) => {
    setSortModel(newSortModel);
    saveDataToLocalStorage(
      generateEntityKeyForLocalStorage(getType(entity), "sortModel", {
        nestedEntity,
        assignModal,
      }),
      newSortModel
    );
  };

  const handlePinnedColumnsChange = (updatedPinnedColumns) => {
    setPinnedColumns(updatedPinnedColumns);
    saveDataToLocalStorage(
      generateEntityKeyForLocalStorage(entity, "pinnedColumns", {
        nestedEntity,
        assignModal,
      }),
      updatedPinnedColumns
    );

    // Save column order as well with this callback since handleColumnOrderChange is not called when pinning/unpinning columns
    // Saving is useful especially remembering the order of columns after pinning/unpinning columns
    const clearedColumns = getColumnOrderAfterPin(
      getColumns,
      updatedPinnedColumns
    );
    saveDataToLocalStorage(
      generateEntityKeyForLocalStorage(entity, "columnOrder", {
        nestedEntity,
        assignModal,
      }),
      clearedColumns
    );
  };

  const handleColumnOrderChange = (column) => {
    const currentColumns = getColumns();

    const oldIndex = column.oldIndex;
    const newIndex = column.targetIndex;

    // Delete the item from its current position
    const reorderedColumn = currentColumns.splice(oldIndex, 1);
    // Move the item to its new position
    currentColumns.splice(newIndex, 0, reorderedColumn[0]);

    const startIndex = 1; // item at index 0 is the checkbox column
    const endIndex = currentColumns.length - 1; //  item at index last item is the actions column
    const cleanedColumns = currentColumns.slice(startIndex, endIndex);

    saveDataToLocalStorage(
      generateEntityKeyForLocalStorage(entity, "columnOrder", {
        nestedEntity,
        assignModal,
      }),
      cleanedColumns
    );
  };

  const handleDeleteClick = useCallback(
    async (entry, action) => {
      const entityNumber = getEntityReference(entry.row);
      if (isAllowedToHardDelete && action === DELETE_ACTIONS.DELETE) {
        dispatchUIState({ type: DIALOG_DELETE_TOGGLE, payload: entry.row });
        return;
      }
      const deleteConsent = window.confirm(
        `Do you want to ${action} ${entityNumber}?`
      );
      if (deleteConsent === true) {
        try {
          if (action === DELETE_ACTIONS.DELETE) {
            await softDeleteEntry(entry.row);
          } else {
            await restoreEntry(entry.row);
          }
          setFeedback({
            isOpen: true,
            messages: [`Successfully ${action}d ${entityNumber}`],
            severity: SEVERITY.SUCCESS,
          });
        } catch (e) {
          setFeedback({
            isOpen: true,
            messages: [e?.response?.body?.status.message],
            severity: SEVERITY.ERROR,
          });
        }

        // Filterstring comes from state
        refreshData(filterString);
      }
    },
    [softDeleteEntry, filterString, refreshData, restoreEntry, setFeedback]
  );

  const handleNavigationClick = useCallback(
    (params) => {
      // from will be used to scroll to the row after revisit from the entity detail page
      history.push(params.row.href, { from: params.row.id });
      // write to session storage
      const gridScrollPosition = apiRef.current?.getScrollPosition();
      sessionStorage.setItem(
        "previousEntityScrollPosition",
        JSON.stringify(gridScrollPosition)
      );
    },
    [history]
  );
  const handleFilterChange = (newFilterModel) => {
    // Check that we don't have any empty filterset (as on initial load)
    if (newFilterModel.items.length !== 0) {
      // Filter is changed by user, set init to false
      newFilterModel.init = false;

      setFilterModel(newFilterModel);
      const filters = newFilterModel.items.reduce(filterReducer, {});
      saveDataToLocalStorage(
        generateEntityKeyForLocalStorage(entity, "filterModel", {
          assignModal,
          nestedEntity,
        }),

        {
          filterJSON: JSON.stringify(filters),
          filterModel: newFilterModel,
        }
      );
      // reset page to 0
      setCurrentPage(0);
      saveDataToLocalStorage(
        generateEntityKeyForLocalStorage(entity, "page", {
          nestedEntity,
          assignModal,
        }),
        0
      );
    }
  };

  const exportRecordsHandler = (entity, columnsToExport) => {
    let selectedColumns = null;

    if (columnsToExport === EXPORT_VISIBLE_COLUMNS) {
      const visibleColumns = apiRef.current.getVisibleColumns();
      selectedColumns = returnVisibleColumns(visibleColumns);
      setIsExportingVisibleColumns(true);
    } else {
      // use incoming columns prop from parent and only keep the field name
      selectedColumns = columns.map((col) => col.field);
      setIsExportingAllColumns(true);
    }

    // check for items in the array
    if (selectedColumns.length >= 1) {
      exportRecords(filterString, selectedColumns.join(","))
        .catch((err) => {
          setFeedback({
            isOpen: true,
            messages: [err.message],
            severity: SEVERITY.ERROR,
          });
        })
        .finally(() => {
          setIsExportingAllColumns(false);
          setIsExportingVisibleColumns(false);
        });
    } else {
      setFeedback({
        isOpen: true,
        messages: ["No visible columns"],
        severity: SEVERITY.ERROR,
      });
    }
  };

  // =========================
  // Inline Edit
  // =========================
  const MAX_NUMBER_OF_ROWS_TO_EDIT_AT_ONCE = 100;
  const [rowModesModel, setRowModesModel] = useState({});

  const hasUnsavedChanged = Object.values(rowModesModel).some(
    (rowMode) => rowMode.mode === GridRowModes.Edit
  );

  const isInlineEditingEnabled = tableOptions.hasInlineEdit && !assignModal;

  useBeforeUnload(hasUnsavedChanged, history);

  const handleInlineEditBatchSave = useCallback(async () => {
    dispatchUIState({
      type: SET_INLINE_EDIT_SAVE_PROGRESS,
      payload: true,
    });
    try {
      await batchSave(uiState.inlineEdit.batchRowChanges.save, entity);

      setFeedback({
        isOpen: true,
        messages: [
          `Successfully updated ${uiState.inlineEdit.batchRowChanges.save.length} records.`,
        ],
        severity: SEVERITY.SUCCESS,
      });

      const updatedChangedRowsForCancel = mergeArraysById(
        uiState.inlineEdit.batchRowChanges.cancel,
        uiState.inlineEdit.batchRowChanges.save
      );

      dispatchUIState({
        type: SET_BATCH_ROW_CHANGES,
        payload: { value: updatedChangedRowsForCancel, operation: "cancel" },
      });
      dispatchUIState({
        type: SET_BATCH_ROW_CHANGES,
        payload: { value: [], operation: "save" },
      });
      setRowModesModel({});
    } catch (error) {
      const messages =
        error.response?.body?.errors.reduce((acc, item) => {
          if (acc.some((error) => error === item.message)) {
            return acc; // Skip duplicate messages
          }

          const fieldMessage = item.field ? `${item.field}: ` : "";
          const formattedMessage = `${fieldMessage}${getFriendlyMessage(
            item.message
          )}`;

          return [...acc, formattedMessage];
        }, []) || [];

      setFeedback({
        isOpen: true,
        messages: messages,
        severity: SEVERITY.ERROR,
      });
    } finally {
      dispatchUIState({
        type: SET_INLINE_EDIT_SAVE_PROGRESS,
        payload: false,
      });
    }
  }, [uiState.inlineEdit.batchRowChanges.save, entity]);

  const handleCellEditStop = (params, event) => {
    if (
      uiState.inlineEdit.batchRowChanges.save.length >
      MAX_NUMBER_OF_ROWS_TO_EDIT_AT_ONCE
    )
      return;

    const rowIndex = uiState.inlineEdit.batchRowChanges.save.findIndex(
      (cr) => cr.id === params.row.id
    );
    const columnData = apiRef.current.getColumn(params.field);
    const value = apiRef.current?.getCellValue(params.id, params.field);
    // the API accepts date values as strings with the format "YYYY-MM-DD"
    const updatedValue = isDate(value)
      ? dateToString(value)
      : processHasPatternInputValue(value, columnData.hasPattern);

    if (rowIndex === -1) {
      dispatchUIState({
        type: SET_BATCH_ROW_CHANGES,
        payload: {
          value: [
            ...uiState.inlineEdit.batchRowChanges.save,
            {
              id: params.row.id,
              [params.field]: updatedValue,
            },
          ],
          operation: "save",
        },
      });
    } else {
      const clonedChangedRows = [...uiState.inlineEdit.batchRowChanges.save];
      clonedChangedRows[rowIndex] = {
        ...clonedChangedRows[rowIndex],
        [params.field]: updatedValue,
      };
      dispatchUIState({
        type: SET_BATCH_ROW_CHANGES,
        payload: { value: clonedChangedRows, operation: "save" },
      });
    }

    if (params.field.includes("client") && entity === FILTER_TYPES.project) {
      updateClientField(params, apiRef, event);
    }

    updateRowModesModel(
      params.id,
      { mode: GridRowModes.Edit },
      setRowModesModel
    );
  };

  const handleEditCellChange = (params) => {
    setFeedback({
      isOpen: false,
    });

    const rowIndex = uiState.inlineEdit.batchRowChanges.cancel.findIndex(
      (changedRow) => changedRow.id === params.row.id
    );
    if (rowIndex === -1) {
      dispatchUIState({
        type: SET_BATCH_ROW_CHANGES,
        payload: {
          value: [
            ...uiState.inlineEdit.batchRowChanges.cancel,
            { ...params.row },
          ],
          operation: "cancel",
        },
      });
    }
  };

  const handleEditCancelClick = useCallback(
    (params) => {
      const { id } = params.row;
      const rowModels = Array.from(apiRef.current.getRowModels().values());
      const updatedRows = rowModels.map((row) => {
        if (row.id === id) {
          const rowInitialState =
            uiState.inlineEdit.batchRowChanges.cancel.find(
              (changedRow) => changedRow.id === id
            );
          return rowInitialState || row;
        }
        return row;
      });

      setFilteredRows(updatedRows);

      dispatchUIState({
        type: SET_BATCH_ROW_CHANGES,
        payload: {
          value: uiState.inlineEdit.batchRowChanges.save.filter(
            (changedRow) => changedRow.id !== id
          ),
          operation: "save",
        },
      });

      updateRowModesModel(id, { mode: GridRowModes.View }, setRowModesModel);
    },
    [
      uiState.inlineEdit.batchRowChanges.save,
      uiState.inlineEdit.batchRowChanges.cancel,
      apiRef,
    ]
  );

  // enable/disable inline edit batch cancel button
  useEffect(() => {
    dispatchUIState({
      type: INLINE_EDIT_BATCH_ACTIONS_TOGGLE,
      payload: !hasUnsavedChanged,
    });
  }, [hasUnsavedChanged]);

  const handleinlineEditBatchCancel = useCallback(() => {
    const rowModels = Array.from(apiRef.current.getRowModels().values());
    const updatedRows = rowModels.map((row) => {
      const rowInitialState = uiState.inlineEdit.batchRowChanges.cancel.find(
        (changedRow) => changedRow.id === row.id
      );
      return rowInitialState || row;
    });

    setFilteredRows(updatedRows);
    dispatchUIState({
      type: SET_BATCH_ROW_CHANGES,
      payload: { value: [], operation: "save" },
    });
    setRowModesModel({});
  }, [uiState.inlineEdit.batchRowChanges.cancel, apiRef]);

  const handleSelectionModelChange = (selectionModel, details) => {
    dispatchUIState({
      type: SELECT_ROW,
      payload: selectionModel.map((id) => details.api.getRow(id)),
    });
  };

  const SUPPORTED_CONTROLS = generateControlsArray(
    tableOptions,
    uiState,
    dispatchUIState,
    handleinlineEditBatchCancel,
    handleInlineEditBatchSave,
    entity
  );

  const utilityColumns = useMemo(
    () => [
      {
        width: isInlineEditingEnabled ? 150 : 100,
        field: "actions",
        type: "actions",
        headerName: "Actions",
        hideable: false,
        disableColumnMenu: false,
        getActions: (params) => {
          const isInEditMode =
            rowModesModel[params.id]?.mode === GridRowModes.Edit;
          return [
            <Stack direction='row' spacing={0}>
              {options.hasCopy && (
                <GridActionsCellItem
                  icon={<FileCopyIcon />}
                  onClick={() => handleCopyClick(params)}
                  label='Copy'
                  title='Copy'
                  disabled={isInEditMode}
                />
              )}
              {/* check params.row for deleted flag, hide if deleted */}
              {options.hasDelete && !params.row.deleted && (
                <GridActionsCellItem
                  icon={<DeleteForeverIcon />}
                  onClick={() =>
                    handleDeleteClick(params, DELETE_ACTIONS.DELETE)
                  }
                  label='Delete'
                  title='Delete'
                  data-testid='delete-row'
                />
              )}
              {options.hasDelete && Boolean(params.row.deleted) && (
                <GridActionsCellItem
                  icon={<RestoreIcon />}
                  onClick={() =>
                    handleDeleteClick(params, DELETE_ACTIONS.RESTORE)
                  }
                  label='Restore'
                  title='Restore'
                />
              )}
              {isInlineEditingEnabled && (
                <>
                  {
                    <GridActionsCellItem
                      icon={
                        <CancelOutlinedIcon
                          data-testid='cancel-inline-edit-row'
                          color={
                            isInlineEditIconsDisabled(
                              uiState.inlineEdit,
                              isInEditMode
                            )
                              ? "disabled"
                              : "error"
                          }
                        />
                      }
                      onClick={(event) => {
                        stopPropagation(event);
                        handleEditCancelClick(params);
                      }}
                      disabled={isInlineEditIconsDisabled(
                        uiState.inlineEdit,
                        isInEditMode
                      )}
                      label='Cancel'
                      title='Cancel'
                    />
                  }
                </>
              )}
              <GridActionsCellItem
                icon={<ArrowRightIcon />}
                onClick={() => handleNavigationClick(params)}
                label='Go to entity'
              />
            </Stack>,
          ];
        },
      },
    ],
    [
      handleDeleteClick,
      handleEditCancelClick,
      handleNavigationClick,
      isInlineEditingEnabled,
      options.hasCopy,
      options.hasDelete,
      rowModesModel,
      uiState.inlineEdit.saveInProgress,
    ]
  );

  const CustomToolbar = useCallback(
    () => (
      <GridToolbarContainer>
        <Stack
          direction={"row"}
          spacing={1}
          sx={{
            alignItems: "center",
            marginBottom: 1,
          }}
        >
          <GridToolbarFilterButton
            data-testid='filter-button'
            ref={setFilterButtonEl}
            sx={{
              minWidth: 52,
              color: theme.palette.black,
              backgroundColor: theme.palette.grey.light,
              "&:hover": {
                backgroundColor: theme.palette.grey.light,
                opacity: 0.8,
                transition: "opacity 0.3s",
              },
              "& .MuiButton-startIcon": {
                margin: 0,
              },
            }}
            title={`FIlter applied on the ${snakeCaseToNormalCase(
              getType(entity)
            )}`}
          />
          <Typography
            variant='h6'
            sx={{ textTransform: "capitalize", fontSize: 18 }}
          >
            {`${snakeCaseToNormalCase(getType(entity))}s overview`}
          </Typography>
        </Stack>
      </GridToolbarContainer>
    ),
    []
  );

  const getColumns = useCallback(() => {
    const unhideableCheckboxColumn = [
      {
        ...GRID_CHECKBOX_SELECTION_COL_DEF,
        hideable: false,
        headerName: "Checkbox Selection",
        cellClassName: "MuiDataGrid-cellCheckbox",
        headerClassName: "MuiDataGrid-columnHeaderCheckbox",
        disableColumnMenu: false,
        width: 55,
      },
    ];

    // JSON.stringify do not write the fields with function values
    // So after order change or pinning columns, the columns will be saved in the local storage
    // without the function values, so we need to add the function values back
    // We have formatter functions defined in the container components
    // And we have modifier here to add custom operators and other properties for data grid
    const localColumns = readDataFromLocalStorage(
      generateEntityKeyForLocalStorage(entity, "columnOrder", {
        nestedEntity,
        assignModal,
      })
    );

    if (localColumns) {
      // First add formatters to the localColumns
      const transformedColumns = transformColumns(localColumns);

      return [
        ...unhideableCheckboxColumn,
        ...modifyColumns(transformedColumns),
        ...utilityColumns,
      ];
    } else {
      return [
        ...unhideableCheckboxColumn,
        ...modifyColumns(columns),
        ...utilityColumns,
      ];
    }
  }, [
    entity,
    nestedEntity,
    assignModal,
    transformColumns,
    columns,
    modifyColumns,
    utilityColumns,
  ]);

  const handleColumnVisibilityChange = (newModel = {}) => {
    dispatchUIState({ type: SET_VISIBLE_COLUMNS, payload: newModel });
    saveDataToLocalStorage(
      generateEntityKeyForLocalStorage(entity, "columnVisibilityModel", {
        nestedEntity,
        assignModal,
      }),
      newModel
    );

    // If we use getColumns() here, we will NOT get the cached columns widths
    const columns = apiRef.current.getAllColumns();

    const visibleGridColumns = columns.map((col) => {
      if (newModel[col.field]) {
        col.hide = !newModel[col.field];
      }

      return col;
    });
    setGridColumns(visibleGridColumns);
  };

  // =========================
  // Side Effects
  // =========================

  useEffect(() => {
    setFilteredRows(rows);
  }, [rows]);

  useEffect(() => {
    const filters = filterModel.items.reduce(filterReducer, {});

    // guard for preventing additional request when init filterModel
    if (filterModel.init === false) {
      setFilters(JSON.stringify(filters));
      setFilterString(JSON.stringify(filters));
    }
  }, [filterModel, setFilters]);

  useEffect(() => {
    // early return when nestedEntity or assignModal is true
    if (nestedEntity || assignModal) return;

    // Only restore scroll position after the data has been loaded
    if (loading === LOADING_STATE_FULFILLED) {
      // Scroll position from navigation
      const previousEntityId = sessionStorage.getItem("previousEntityId");
      const previousEntityScrollPosition = sessionStorage.getItem(
        "previousEntityScrollPosition"
      );

      // Determine which scroll position to use - prioritize most recent action
      let position = null;
      let rowId = null;

      // Scroll position from copy operation
      // Copy operation takes precedence as it's more recent
      if (copyScrollPositionRef.current) {
        position = copyScrollPositionRef.current;
        rowId = copiedFromIdRef.current;
      } else if (previousEntityScrollPosition) {
        position = JSON.parse(previousEntityScrollPosition);
        rowId = previousEntityId;
      }

      // Find the row to highlight
      const rowIndex = rowId
        ? filteredRows.findIndex((row) => row.id === rowId)
        : -1;

      // Restore the scroll position after a short delay to ensure the grid is ready
      if (position) {
        setTimeout(() => {
          apiRef.current?.scroll(position);
        }, 500);
      }

      // Visually highlight the row to help users locate it
      if (rowIndex >= 0) {
        setTimeout(() => {
          const elements = document.querySelectorAll(
            `[data-rowIndex="${rowIndex}"]`
          );
          handleRowHighlight(elements);
        }, 750);
      }

      // Clean up to prevent incorrect restoration on subsequent renders
      sessionStorage.removeItem("previousEntityId");
      sessionStorage.removeItem("previousEntityScrollPosition");
      copyScrollPositionRef.current = null;
      copiedFromIdRef.current = null;
    }
  }, [apiRef, assignModal, filteredRows, loading, nestedEntity, offset]);

  useEffect(() => {
    if (loading !== LOADING_STATE_PENDING && columns.length > 0) {
      const visibleColumns = {};
      const storedVisibleColumns =
        readDataFromLocalStorage(
          generateEntityKeyForLocalStorage(entity, "columnVisibilityModel", {
            nestedEntity,
            assignModal,
          })
        ) || {};
      const storedColumnWidths = readDataFromLocalStorage(
        generateEntityKeyForLocalStorage(entity, "columnWidths", {
          nestedEntity,
          assignModal,
        })
      );
      const visibleGridColumns = [...getColumns()].map((col) => {
        if (storedVisibleColumns[col.field] !== undefined) {
          col.hide = !storedVisibleColumns[col.field];
          visibleColumns[col.field] = storedVisibleColumns[col.field];
        } else {
          visibleColumns[col.field] = !col.hide;
        }

        // check if column has a stored width
        if (storedColumnWidths && storedColumnWidths[col.field]) {
          col.width = storedColumnWidths[col.field];
          delete col.minWidth;
        }

        return col;
      });
      dispatchUIState({ type: SET_VISIBLE_COLUMNS, payload: visibleColumns });
      setGridColumns(visibleGridColumns);
    }
  }, [loading, entity, columns, getColumns]);

  useEffect(() => {
    setOffset(currentPage * rowsPerPage);
    setLimit(rowsPerPage);
  }, [currentPage, rowsPerPage, setOffset, setLimit]);

  // enable/disable controls
  useEffect(() => {
    if (uiState.selectedRows.length < 1) {
      dispatchUIState({ type: CONTROLS_DISABLE });
      return;
    }

    // If there are highlighted rows, remove the highlight after the selection to avoid confusion
    const highlightedElements = document.querySelectorAll(".highlighted-row");
    const highlightedElementsDeleted = document.querySelectorAll(
      ".highlighted-row-deleted"
    );
    if (highlightedElements.length > 0)
      highlightedElements.forEach((el) =>
        el.classList.remove("highlighted-row")
      );
    if (highlightedElementsDeleted.length > 0)
      highlightedElementsDeleted.forEach((el) =>
        el.classList.remove("highlighted-row-deleted")
      );

    dispatchUIState({ type: CONTROLS_ENABLE });
  }, [uiState.selectedRows]);

  // compute the visible columns based on user selection
  const massEditColumns = useMemo(() => {
    if (Object.keys(apiRef.current).length > 0) {
      const visibleColumnsApiRef = apiRef.current.getVisibleColumns();
      const filteredVisibleColumns = columns.filter((col) => {
        return visibleColumnsApiRef.some(
          (visibleColumn) => visibleColumn.field === col.field
        );
      });
      const sortedColumnsByColumnOrder = sortArraysOfObjectsByCommonProperty(
        filteredVisibleColumns,
        visibleColumnsApiRef,
        "field"
      );
      // if there are no visible columns (cold cache)
      // then return all the columns
      return sortedColumnsByColumnOrder.length > 0
        ? sortedColumnsByColumnOrder
        : columns;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uiState.edit.visible]);

  // =========================
  // Render
  // =========================
  return (
    <>
      <Prompt
        when={hasUnsavedChanged}
        message='You have unsaved changes. Are you sure you want to leave?'
      />
      <Box
        sx={{
          width: "100%",
          height: assignModal
            ? "calc(100vh - 280px)"
            : nestedEntity
            ? "50vh"
            : "calc(100vh - 110px)",
        }}
      >
        <ActionBar
          controls={SUPPORTED_CONTROLS}
          entity={entity}
          selectedRows={uiState.selectedRows}
          exportOptions={{
            isAllowedToExport,
            exportRecordsHandler,
            isLoading: isExportingVisibleColumns || isExportingAllColumns,
          }}
          renderCreateEntityButton={renderCreateEntityButton}
          assignModal={assignModal}
        />

        <DataGrid
          editMode={isInlineEditingEnabled ? "cell" : "none"}
          rows={filteredRows}
          onCellEditStart={(params) => {
            if (
              uiState.inlineEdit.batchRowChanges.save.length >
              MAX_NUMBER_OF_ROWS_TO_EDIT_AT_ONCE
            ) {
              dispatchUIState({
                type: DIALOG_INFO_TOGGLE,
                payload:
                  "You can not update more than 100 records at a time. Please first update the records and then continue.",
              });
              return;
            }
            handleEditCellChange(params);
          }}
          onCellEditStop={handleCellEditStop}
          localeText={{
            MuiTablePagination: {
              labelDisplayedRows: ({ from, to, count }) =>
                `Number of records shown: ${from} - ${to} out of ${count}`,
            },
            toolbarFilters: "", // remove the default "Filters" text
          }}
          isCellEditable={(params) => !params.row.is_deleted}
          disableSelectionOnClick
          onRowDoubleClick={(_, event) => stopPropagation(event)}
          columns={gridColumns}
          columnVisibilityModel={uiState.visibleColumns}
          onColumnVisibilityModelChange={handleColumnVisibilityChange}
          onColumnWidthChange={(params) =>
            handleColumnWidthChange(entity, params, {
              nestedEntity,
              assignModal,
            })
          }
          onColumnHeaderClick={handleColumnHeaderClick}
          onColumnOrderChange={handleColumnOrderChange}
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          sortingMode='server'
          page={currentPage}
          pagination // enables pagination
          paginationMode={options.mode}
          pageSize={rowsPerPage} // override default (100)
          pinnedColumns={pinnedColumns}
          onPinnedColumnsChange={handlePinnedColumnsChange}
          apiRef={apiRef}
          filterMode={options.mode}
          // used to populate the filter panel with data
          filterModel={filterModel}
          onFilterModelChange={handleFilterChange}
          onPageChange={handlePageChange}
          rowCount={numberOfRecords} // the total number of rows
          rowsPerPageOptions={[
            ROWS_PER_PAGE,
            ROWS_PER_PAGE * 2,
            ROWS_PER_PAGE * 4,
            ROWS_PER_PAGE * 10,
          ]}
          onPageSizeChange={handleRowsPerPageChange}
          rowHeight={options.rowHeight}
          checkboxSelection
          onSelectionModelChange={handleSelectionModelChange}
          components={{
            Toolbar: CustomToolbar,
          }}
          componentsProps={{
            panel: {
              anchorEl: filterButtonEl,
            },
            toolbar: {
              setFilterButtonEl,
            },
            filterPanel: {
              linkOperators: ["and"],
              columnsSort: "asc",
              sx: {
                ...DATA_GRID_FILTER_PANEL_STYLES,
              },
            },
            columnMenu: {
              sx: {
                "& li:nth-child(4)": {
                  // Filter is the 4th item in the column menu
                  display: "none",
                },
              },
            },
          }}
          disableColumnFilter={!options.filter}
          loading={loading === LOADING_STATE_PENDING}
          sx={DATA_GRID_CONTAINER_STYLES}
          getRowClassName={(params) => {
            return `deleted-${Boolean(params.row.deleted)}`;
          }}
        />
      </Box>
      {/* Conditionally render dialogue */}
      {uiState.edit.visible && (
        <MassEditDialog
          handler={editRecordsHandler}
          visible={uiState.edit.visible}
          setVisible={dispatchUIState}
          rows={uiState.selectedRows}
          fields={massEditColumns}
          entityType={entity}
        />
      )}
      <CopyDialog
        handler={copyHandler}
        visible={uiState.copy.visible}
        setVisible={dispatchUIState}
        rows={uiState.copy.rows}
        entityType={entity}
      />
      <AlertDialog
        visible={uiState.info.visible}
        setVisible={dispatchUIState}
        content={uiState.info.content}
      />
      <DeleteDialog
        visible={uiState.delete.visible}
        setVisible={dispatchUIState}
        deleteState={uiState.delete}
        softDeleteEntry={softDeleteEntry}
        hardDeleteEntry={hardDeleteEntry}
        refreshData={refreshData}
        filterString={filterString}
      />
    </>
  );
}
