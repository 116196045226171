import React, { useContext, useEffect, useMemo } from "react";
import compose from "compose-function";
import moment from "moment";

import TableComponent from "../../components/TableComponent";
import CreateItemButton from "../../components/CreateItemButton";

import { useColumns } from "../../hooks/useColumns";
import useUserPermissionsContext from "../../hooks/useUserPermissionsContext";

import {
  getSortOrder,
  sortColumns,
  sortModelToString,
  fetchDataWithOptionsAndFilters,
  exportToFile,
} from "../../utils/utils";

import {
  addHrefToRow,
  addSignalTypeReferenceField,
  addValueFormatter,
  padValueWith,
  numberFormatter,
  readDataFromLocalStorage,
  getDefaultFilterModel,
  getInitialFilters,
  generateEntityKeyForLocalStorage,
} from "../../utils/table";

import { hardDelete, softDelete } from "../../dao/operations";
import { copyRecord, editRecords, exportRecords } from "../../dao/common";
import { FILTER_TYPES } from "../../dao/types";

import UserRights from "../../enums/UserRights";

import { ROUTES } from "../../enums/Routes";
import { API_ROUTES } from "../../enums/api";
import { addSearchParams } from "../../utils/dao";

import { GlobalContext } from "../../globalContext";
import useEntities from "../../hooks/useEntities";
import { useSortModel } from "../../hooks/useSortModel";
import { Stack } from "@mui/material";
import { AssignButtons } from "../AssignButtons";

const API_ROUTE = API_ROUTES.cables;

/**
 * Here we need to transform the row data retrieved,
 * in order to make it compatible with @mui/x-data-grid-pro
 */
const transformRows = (rows) => {
  return rows.reduce((acc, row) => {
    return [
      ...acc,
      compose(
        addHrefToRow({
          field: "id",
          slug: "cable",
        }),
        addSignalTypeReferenceField
      )(row),
    ];
  }, []);
};

/**
 * Here we need to transform the column data retrieved,
 * in order to add @mui/x-data-grid-pro formatters
 */
const transformColumns = (columns) => {
  return columns.reduce((acc, column) => {
    return [
      ...acc,
      compose(
        addValueFormatter("system_group_number")(padValueWith(3)),
        addValueFormatter("number")(padValueWith(4)),
        addValueFormatter("system_number")(padValueWith(3)),
        addValueFormatter("object_number")(padValueWith(5)),
        addValueFormatter("bundle_number")(numberFormatter),
        addValueFormatter("measured_length")(numberFormatter),
        addValueFormatter("strap_length")(numberFormatter),
        addValueFormatter("maximum_length")(numberFormatter),
        addValueFormatter("calculated_length")(numberFormatter)
      )(column),
    ];
  }, []);
};

export default function CablesContainer({ nestedEntity = false, parent = {} }) {
  const { globalFilters } = useContext(GlobalContext);
  const { hasAssignButtons = false } = parent;
  const assignModal = hasAssignButtons && !nestedEntity;
  const { sortModel, setSortModel } = useSortModel(FILTER_TYPES.cable, {
    nestedEntity,
    assignModal,
  });
  // Get cached local filter model from local storage
  // with a fallback to default field values
  const cachedLocalFilter = readDataFromLocalStorage(
    generateEntityKeyForLocalStorage(FILTER_TYPES.cable, "filterModel", {
      nestedEntity,
      assignModal,
    })
  ) ?? {
    filterModel: null,
    filterJSON: "",
  };

  // destructure fields from cachedLocalFilter
  const {
    filterModel: cachedLocalFilterModel,
    filterJSON: cachedFilterJSONString,
  } = cachedLocalFilter;

  const initialFilters = getInitialFilters({
    cache: {
      localFilterModel: cachedLocalFilterModel,
      filterJSONString: cachedFilterJSONString,
    },
    defaultFilterModel: getDefaultFilterModel("object_number", assignModal)
      .filterModel,
  });

  const { columns } = useColumns(FILTER_TYPES.cable);

  const sortModelString =
    sortModelToString(sortModel, columns) || "cable_number asc"; // see BSED-1608;

  // The `entityAssignedCables` object tracks the assignment of cables to various entities.
  const entityAssignedCables = {
    cable_bundle_ids: parent?.cable_bundle_id ?? null,
    route_point_ids: parent?.route_point_id ?? null,
    drum_ids: parent?.drum_id ?? null,
    order_ids: parent?.order_id ?? null,
  };

  const {
    data,
    loadingState,
    fetchEntitiesData: fetchCablesData,
    setFilterString,
    setOffset,
    limit,
    offset,
    setLimit,
    totalNumberOfRecords,
  } = useEntities("cables", {
    params: {
      object_ids: parent?.object_id ?? globalFilters?.value ?? null,
      section_ids: parent?.section_id ?? null,
      project_ids: parent?.project_id ?? null,
      ...entityAssignedCables,
    },
    initialFilters: initialFilters.apiFilterString,
    order: sortModelString,
  });

  const fetchRecordsDataWithGlobalFilters = fetchDataWithOptionsAndFilters(
    fetchCablesData
  )({
    limit,
    offset,
    order: sortModelString,
    params: {
      object_ids: parent?.object_id ?? globalFilters?.value ?? null,
      section_ids: parent?.section_id ?? null,
      ...entityAssignedCables,
    },
  });

  const fetchExportData = async (filterString, selectedColumnsString) => {
    const abortController = new AbortController();
    const searchParams = {
      filters: filterString,
      columns: selectedColumnsString,
      object_ids: parent?.object_id ?? globalFilters?.value ?? null,
      section_ids: parent?.section_id ?? null,
      ...entityAssignedCables,
    };
    const response = await exportRecords(
      API_ROUTE,
      searchParams,
      abortController
    );

    exportToFile(
      `export_${FILTER_TYPES.cable}_overview_${moment().format(
        "DD-MM-YYYY"
      )}.csv`,
      response
    );

    return "Export done";
  };

  const { isAllowedTo } = useUserPermissionsContext();

  const transformedRows = useMemo(() => transformRows(data), [data]);
  const transformedColumns = useMemo(
    () => transformColumns(sortColumns(columns)),
    [columns]
  );
  const isAllowedToCreate = isAllowedTo(UserRights.CREATE, FILTER_TYPES.cable);
  const isAllowedToDelete = isAllowedTo(
    UserRights.SOFT_DELETE,
    FILTER_TYPES.cable
  );
  const isAllowedToExport = isAllowedTo(UserRights.EXPORT_CSV);
  const isAllowedToMassEdit = isAllowedTo(UserRights.MASS_EDIT_CABLE);
  const isAllowedToInlineEdit = isAllowedTo(UserRights.INLINE_EDIT_CABLE);

  const tableOptions = {};
  tableOptions.hasDelete = isAllowedToDelete;
  tableOptions.hasCopy = isAllowedToCreate;
  tableOptions.hasMassEdit = isAllowedToMassEdit;
  tableOptions.sortOrder = getSortOrder(FILTER_TYPES.cable);
  tableOptions.hasInlineEdit = isAllowedToInlineEdit;

  const searchParams = new URLSearchParams(
    Object.entries({
      objectId: parent?.object_id || globalFilters?.value,
      projectId: parent.project_id, // defined when sub overview
      systemGroupId: parent.system_group_id, // defined when sub overview
      systemId: parent.system_id, // defined when sub overview
      sourceSectionId: parent.section_id, // defined when sub overview
      locationId: parent.location_id, // defined when sub overview
      cableBundleId: parent.cable_bundle_id, // defined when sub overview
      drumId: parent.drum_id, // defined when sub overview
      orderId: parent.order_id, // defined when sub overview
    }).filter(([, value]) => value)
  ).toString();

  useEffect(() => {
    parent?.options?.assignCableModel?.isAssigned &&
      fetchRecordsDataWithGlobalFilters();
    // Disabled the eslint warning because we want to run the effect only on changes of the assignCableModel
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parent?.options?.assignCableModel]);

  return (
    <TableComponent
      {...{
        columns: transformedColumns,
        transformColumns,
        loading: loadingState,
        rows: transformedRows,
        refreshData: fetchRecordsDataWithGlobalFilters,
        renderCreateEntityButton: (option) => {
          return (
            <Stack direction='row' spacing={2}>
              {hasAssignButtons ? (
                <AssignButtons
                  nestedEntity={nestedEntity}
                  options={{
                    selectedRows: option.selectedRows,
                    assignTo: parent.entity,
                    entity: FILTER_TYPES.cable,
                    handleAssignment: parent?.options?.handleAssignment,
                  }}
                />
              ) : (
                <CreateItemButton
                  to={addSearchParams(ROUTES.cable.create)(searchParams)}
                  isDisabled={!isAllowedToCreate}
                  entity={FILTER_TYPES.cable}
                />
              )}
            </Stack>
          );
        },
        copyEntry: copyRecord,
        editRecords,
        softDeleteEntry: softDelete({
          route: API_ROUTE,
          delete: true,
        }),
        hardDeleteEntry: hardDelete({
          route: API_ROUTE,
        }),
        restoreEntry: softDelete({
          route: API_ROUTE,
          delete: false,
        }),
        tableOptions,
        setFilters: setFilterString,
        setOffset,
        offset,
        setLimit,
        sortModel,
        setSortModel,
        numberOfRecords: totalNumberOfRecords,
        isAllowedToExport: assignModal ? false : isAllowedToExport,
        entity: FILTER_TYPES.cable,
        nestedEntity: nestedEntity,
        initialFilterModel: initialFilters.grid,
        assignModal,
        exportRecords: fetchExportData,
      }}
    />
  );
}
